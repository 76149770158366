import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { jiaoduiFileNameInfo } from '../../../types';

export default function FileDetailsTable({
  filenameInfo,
  resolution,
}: {
  filenameInfo: jiaoduiFileNameInfo;
  resolution: number;
}) {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: 500, margin: 'auto', marginTop: 5 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>类别</TableCell>
              <TableCell>文件名信息</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>分辨率</TableCell>
              <TableCell>{resolution === 0 ? '1080p' : '2160p'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>校对</TableCell>
              <TableCell>{filenameInfo.jiaodui}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Youtuber</TableCell>
              <TableCell>{filenameInfo.youtuber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>原标题</TableCell>
              <TableCell>{filenameInfo.title}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
