import React, { useEffect } from 'react';
import { Button, ButtonGroup, Link } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useSnackbar } from '../../../context/SnackbarContext';
import { TaskInfo } from '../../../types';
import taskInfoHandle from '../../../utils/tingyi/taskInfoHandle';

interface TaskDetailsTableProps {
  taskInfo: TaskInfo;
  setTaskInfo: React.Dispatch<React.SetStateAction<TaskInfo>>;
  taskInfoMessage: string;
  taskInfoHandleStatus: boolean;
  setTaskInfoHandleStatus: React.Dispatch<React.SetStateAction<boolean>>;
  isMultipleTingYi: boolean;
  setIsMultipleTingYi: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTingYi: string;
  setSelectedTingYi: React.Dispatch<React.SetStateAction<string>>;
  isHandled: boolean;
  setIsHandled: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function taskDetailsTable({
  taskInfo,
  setTaskInfo,
  taskInfoMessage,
  taskInfoHandleStatus,
  setTaskInfoHandleStatus,
  isMultipleTingYi,
  setIsMultipleTingYi,
  selectedTingYi,
  setSelectedTingYi,
  isHandled,
  setIsHandled,
}: TaskDetailsTableProps) {
  const { openSnackbar } = useSnackbar();

  // Function to generate TingYi selection buttons
  const generateTingYiButtons = () => {
    return taskInfo.contributors.tingYiList.map((name) => (
      <Button
        key={name}
        variant={selectedTingYi === name ? 'contained' : 'outlined'}
        color='primary'
        onClick={() => {
          setSelectedTingYi(name);
          setTaskInfoHandleStatus(true);
          openSnackbar(name, 'info');
        }}
        sx={{ textTransform: 'none' }}
      >
        {name}
      </Button>
    ));
  };

  // UseEffect to observe changes to selectedTingYi
  useEffect(() => {
    if (selectedTingYi) {
      console.log('被选中的听译：', selectedTingYi);
    }
  }, [selectedTingYi]);

  // Construct the taskInfo
  const constructTaskInfo = ({
    name,
    taskInfo,
    setTaskInfo,
  }: {
    name: string;
    taskInfo: TaskInfo;
    setTaskInfo: React.Dispatch<React.SetStateAction<TaskInfo>>;
  }) => {
    const ytber = taskInfo.contributors.ytber;
    const preOutputFileName = taskInfo.outputFileName;
    taskInfo.outputFileName = `听译-${name}-【${ytber}】${preOutputFileName}`;
    setTaskInfo(taskInfo);
  };

  // Set isHandled to false when taskInfoMessage changes
  useEffect(() => {
    if (taskInfoMessage) {
      setIsHandled(false);
    }
  }, [taskInfoMessage]);

  // Trigger `constructTaskInfo` when `selectedTingYi` changes and is not empty
  useEffect(() => {
    if (selectedTingYi) {
      constructTaskInfo({
        name: selectedTingYi,
        taskInfo,
        setTaskInfo,
      });
    }
  }, [selectedTingYi, taskInfo, setTaskInfo]);

  return (
    <>
      {!isHandled && !taskInfoHandleStatus && (
        <Button
          variant='contained'
          fullWidth
          color='secondary'
          sx={{
            mt: 2,
            width: '50%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          onClick={() => {
            if (taskInfoMessage === '') {
              openSnackbar('请先输入任务信息', 'error');
              return;
            } else {
              setTaskInfo(
                taskInfoHandle({
                  taskInfo: taskInfo,
                  content: taskInfoMessage,
                }),
              );
              if (!taskInfo.ytbUrl) {
                openSnackbar('未检测到YouTube链接', 'error');
              } else if (!taskInfo.driveUrl) {
                openSnackbar('未检测到网盘链接', 'error');
              } else if (!taskInfo.contributors.ytber) {
                openSnackbar('未检测到YouTuber信息', 'error');
              } else if (!taskInfo.contributors.originName) {
                openSnackbar('未检测到原视频名', 'error');
              } else if (!taskInfo.contributors.tingYiList.length) {
                openSnackbar('未检测到人员信息', 'error');
              } else if (!taskInfo.contributors.jiaoDuiList.length) {
                openSnackbar('未检测到校对信息', 'error');
              } else if (!taskInfo.contributors.shiZhouList.length) {
                openSnackbar('未检测到时轴信息', 'error');
              } else {
                if (taskInfo.contributors.tingYiList.length > 1) {
                  openSnackbar('检测到多名听译，请选择你的ID', 'warning');
                  setIsMultipleTingYi(true);
                } else {
                  setIsMultipleTingYi(false);
                  openSnackbar(
                    '自动检查任务信息成功，下载前请再次自行检查是否正确',
                    'info',
                  );
                  setSelectedTingYi(taskInfo.contributors.tingYiList[0]);
                  setTaskInfoHandleStatus(true);
                }
              }
              setIsHandled(true);
            }
          }}
        >
          自动检查任务信息
        </Button>
      )}
      {/* After Handled show the details table */}
      {(isHandled || taskInfoHandleStatus) && (
        <TableContainer
          component={Paper}
          sx={{ maxWidth: 600, margin: 'auto' }}
        >
          <Table sx={{ minWidth: 200 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>类别</TableCell>
                <TableCell align='center'>信息</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key='ytber'>
                <TableCell component='th' scope='row' align='center'>
                  YouTuber
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  {taskInfo.contributors.ytber}
                </TableCell>
              </TableRow>
              <TableRow key='originName'>
                <TableCell component='th' scope='row' align='center'>
                  原视频名
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  {taskInfo.contributors.originName}
                </TableCell>
              </TableRow>
              <TableRow key='ytbUrl'>
                <TableCell component='th' scope='row' align='center'>
                  YouTube链接
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  <Link
                    href={taskInfo.ytbUrl}
                    target='_blank'
                    underline='hover'
                  >
                    {taskInfo.ytbUrl}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow key='driveUrl'>
                <TableCell component='th' scope='row' align='center'>
                  私有网盘链接
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  <Link
                    href={taskInfo.driveUrl}
                    target='_blank'
                    underline='hover'
                  >
                    {taskInfo.driveUrl}
                  </Link>
                </TableCell>
              </TableRow>
              {/* 下面是人员信息 */}
              <TableRow key='tingYiList'>
                <TableCell component='th' scope='row' align='center'>
                  听译
                  {taskInfo.contributors.tingYiList.length > 1
                    ? ` ${taskInfo.contributors.tingYiList.length} 人`
                    : ''}
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  {taskInfo.contributors.tingYiList.join('、')}
                </TableCell>
              </TableRow>
              <TableRow key='jiaoDuiList'>
                <TableCell component='th' scope='row' align='center'>
                  校对
                  {taskInfo.contributors.jiaoDuiList.length > 1
                    ? ` ${taskInfo.contributors.jiaoDuiList.length} 人`
                    : ''}
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  {taskInfo.contributors.jiaoDuiList.join('、')}
                </TableCell>
              </TableRow>
              <TableRow key='shiZhouList'>
                <TableCell component='th' scope='row' align='center'>
                  时轴
                  {taskInfo.contributors.shiZhouList.length > 1
                    ? ` ${taskInfo.contributors.shiZhouList.length} 人`
                    : ''}
                </TableCell>
                <TableCell component='th' scope='row' align='center'>
                  {taskInfo.contributors.shiZhouList.join('、')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isMultipleTingYi && selectedTingYi === '' && isHandled && (
        <Typography
          variant='h6'
          component='div'
          color='error'
          sx={{
            mt: 2,
            textAlign: 'center',
          }}
        >
          检测到多名听译，请选择你的ID
        </Typography>
      )}
      {isMultipleTingYi && (isHandled || taskInfoHandleStatus) && (
        <ButtonGroup
          size='large'
          color='secondary'
          sx={{
            mt: 2,
            width: '90%',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          aria-label='large outlined primary button group'
        >
          {generateTingYiButtons()}
        </ButtonGroup>
      )}
    </>
  );
}
