import React from 'react';
import { Avatar, IconButton, Tooltip } from '@mui/material';

export default function AccountSettingsIcon({
  handleClick,
  open,
}: {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  open: boolean;
}) {
  const userAvatar = 'https://s2.loli.net/2024/01/17/CGeZhq1FYovxz2p.jpg';
  return (
    // {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
    // <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
    <Tooltip title='开发中'>
      <IconButton
        onClick={handleClick}
        size='small'
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar sx={{ width: 32, height: 32 }} src={userAvatar} />
      </IconButton>
    </Tooltip>
  );
}
