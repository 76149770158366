import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Toolbar,
  Typography,
} from '@mui/material';
import { IconButton } from '@mui/material';

import SettingsIcon from './accountSettingsIcon';
import SettingsMenu from './accountSettingsMenu';
import SideDrawer from './sideDrawer';

export default function TopAppBar({
  handleDrawerToggle,
  drawerOpen,
  isMobile,
  navigate,
}: {
  handleDrawerToggle: () => void;
  drawerOpen: boolean;
  isMobile: boolean;
  navigate: NavigateFunction;
}) {
  // anchorEl used to control the position of the menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerToggle}
              edge='start'
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Button color='inherit' onClick={() => navigate('/')}>
              <Typography variant='h6' component='div'>
                白鲸工作台
              </Typography>
            </Button>
          </Box>
          <Box>
            <SettingsIcon handleClick={handleClick} open={open} />
            {/* Use anchorEl as prop in order to position the menu at right place */}
            <SettingsMenu handleClose={handleClose} anchorEl={anchorEl} />
          </Box>
        </Toolbar>
      </AppBar>
      <SideDrawer open={drawerOpen} isMobile={isMobile} navigate={navigate} />
    </>
  );
}
