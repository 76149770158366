import React from 'react';
// import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  // Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// import { AuthContext, AuthProvider } from './context/AuthContext';
import { SnackbarProvider } from './context/SnackbarContext';
import Tingyi from './pages/workspace/tingyi';
import Workspace from './pages/workspace/Workspace';
import Yazhi from './pages/workspace/yazhi';

// import SignIn from './sign-in/SignIn';
// import SignUp from './sign-up/SignUp';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#48acec',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ee4f87',
      contrastText: '#fff',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <AuthProvider> */}
      <SnackbarProvider>
        <Router>
          <Routes>
            {/* <Route path='/sign-in' element={<SignIn />} />
            <Route path='/sign-up' element={<SignUp />} /> */}
            {/* 根路径的逻辑由 RootRoute 组件处理 */}
            {/* <Route path='/' element={<RootRoute />} /> */}
            {/* 定义主工作区路由及其子页面 */}
            <Route path='/' element={<Workspace />}>
              <Route index element={<Workspace />} />
              <Route path='tingyi' element={<Tingyi />} />
              <Route path='yazhi' element={<Yazhi />} />
            </Route>
          </Routes>
        </Router>
      </SnackbarProvider>
      {/* </AuthProvider> */}
    </ThemeProvider>
  );
}

// RootRoute 组件决定根路径应该渲染什么内容
// const RootRoute = () => {
//   const { isAuthenticated } = useContext(AuthContext); // 使用 useContext 获取当前的认证状态

//   // 如果用户已认证（即 isAuthenticated 为 true），则渲染 Dashboard 组件
//   // 否则，重定向到登录页面
//   return isAuthenticated ? <Workspace /> : <Navigate to='/sign-in' replace />;
// };

export default App;
