import { TaskInfo } from '../../types';

const YT_URL_PREFIX = 'https://youtu.be/';
const DRIVE_URL_PREFIX = 'https://pan.belugasubs.com/s/';

const taskInfoHandle = ({
  taskInfo,
  content,
}: {
  taskInfo: TaskInfo;
  content: string;
}) => {
  const lines = content.split('\n');
  taskInfo.contributors.ytber = lines[0];

  const originName = lines[1];
  taskInfo.contributors.originName = originName;

  // Linux/Windows/macOS下的文件名非法字符删除掉
  // https://en.wikipedia.org/wiki/Filename#Reserved_characters_and_words
  // 包括 / \ : * ? " < > | % ; , . = $ ' `
  taskInfo.outputFileName = originName.replace(/[\\/:*?"<>|%;,.=$'`]/g, '');

  let currentState = '';

  // 清空原有的人员信息
  taskInfo.contributors.tingYiList = [];
  taskInfo.contributors.jiaoDuiList = [];
  taskInfo.contributors.shiZhouList = [];

  for (const line of lines.slice(2).map((line) => line.trim())) {
    if (!line) continue;

    if (line.startsWith(YT_URL_PREFIX)) {
      taskInfo.ytbUrl = line;
    } else if (line.startsWith(DRIVE_URL_PREFIX)) {
      taskInfo.driveUrl = line;
    }

    if (line.startsWith('听译')) {
      currentState = 'tingyi';
    } else if (line.startsWith('校对')) {
      currentState = 'jiaodui';
    } else if (line.startsWith('时轴') || line.startsWith('压制')) {
      currentState = 'shizhou';
    }

    const names = line.match(/@(\S+)/g)?.map((name) => name.substring(1));
    if (names) {
      switch (currentState) {
        case 'tingyi':
          taskInfo.contributors.tingYiList.push(...names);
          break;
        case 'jiaodui':
          taskInfo.contributors.jiaoDuiList.push(...names);
          break;
        case 'shizhou':
          taskInfo.contributors.shiZhouList.push(...names);
          break;
      }
    }
  }
  return taskInfo;
};

export default taskInfoHandle;
