import React from 'react';
import { Box, Link } from '@mui/material';
import Typography, { TypographyProps } from '@mui/material/Typography';

function Copyright(props: TypographyProps) {
  return (
    <Box
      sx={{
        textAlign: 'center', // 文本居中
      }}
    >
      <Typography
        variant='body2'
        color='text.secondary'
        align='center'
        {...props}
      >
        {'Copyright © '}
        <Link
          color='inherit'
          href='https://www.belugasubs.com/'
          target='_blank'
          rel='external noreferrer noopener'
          underline='hover'
        >
          BelugaSubs
        </Link>{' '}
        2019-{new Date().getFullYear()}
        <br />
        {'All Rights Reserved'}
        <br />
        <Link
          color='inherit'
          href='https://icp.gov.moe/?keyword=20240728'
          target='_blank'
          rel='external noreferrer noopener'
          underline='hover'
        >
          萌ICP备20240728号
        </Link>
        {'\t\t'}
        <Link
          color='inherit'
          href='http://beian.miit.gov.cn'
          target='_blank'
          rel='external noreferrer noopener'
          underline='hover'
        >
          鲁ICP备19057855号
        </Link>
      </Typography>
    </Box>
  );
}

export default Copyright;
