import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

export default function Guide() {
  return (
    <Box
      sx={{
        margin: 2,
        maxWidth: 600,
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Typography variant='h6' component='div'>
        操作指南
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary='1. 文件格式：'
            secondary={
              <>
                <Typography component='span'>
                  a) docx文件，包含了字幕和标准格式
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary='2. 下载步骤：'
            secondary={
              <>
                <Typography component='span'>
                  a) 将你的任务分配消息复制并粘贴到下方文本框中
                </Typography>
                <br />
                <Typography component='span'>
                  b) 点击下载按钮开始下载，耐心等待，约3-5s
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary='3. 注意：'
            secondary={
              <>
                <Typography component='span'>
                  a) 文件头还需翻译
                  <u style={{ fontWeight: 'bold' }}>视频标题</u>。
                </Typography>
                <br />
                <Typography component='span'>
                  b) 正文内容请参照最新版本
                  <u style={{ fontWeight: 'bold' }}>[听译]操作规范</u>
                  确保格式准确
                </Typography>
                <br />
                <Typography component='span'>
                  c) 文件末尾的注释或硬字幕，
                  <u style={{ fontWeight: 'bold' }}>根据需要删减</u>
                  （若没有请删除，不要留空）
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
}
