import { jiaoduiFileNameInfo } from '../../types';

// 1. Uploaded jiaodui file
interface uploadFileHandlerProps {
  filename: string;
  openSnackbar: (message: string, severity: 'success' | 'error') => void;
}

const uploadFileHandler = ({
  filename,
  openSnackbar,
}: uploadFileHandlerProps) => {
  // a. Check file name starts with “校对”
  if (!filename.startsWith('校对')) {
    openSnackbar('请上传校对文件', 'error');
    return null;
  }
  // b. Check file name is correct format (2 '-' in file name)
  if (filename.split('-').length !== 3) {
    openSnackbar('文件名格式错误，请使用-进行分割，并通知校对', 'error');
    return null;
  }
  // c. Gather task info from file name and return
  // i. Get jiaodui id from file name, which is the second part
  const jiaodui = filename.split('-')[1];
  // ii. Get youtuber's name, inside 【】
  const matchYoutuber = filename.match(/【(.*?)】/);
  if (!matchYoutuber) {
    openSnackbar('文件名格式错误，请使用【】标注Youtuber', 'error');
    return null;
  }
  const youtuber = matchYoutuber[1];

  // iii. Get video title, which is after 】
  const title = filename.split('】')[1].split('.docx')[0].trim();
  if (!title) {
    openSnackbar('文件名格式错误，未找到原标题', 'error');
    return null;
  }

  // c. Return gathered info
  const taskInfoFromFileName: jiaoduiFileNameInfo = {
    jiaodui,
    youtuber,
    title,
  };
  return taskInfoFromFileName;
};

export default uploadFileHandler;

// 2. Downloaded auto sub ass file
