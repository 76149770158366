import axios from 'axios';

interface UploadResponse {
  taskId: number;
  url?: string;
}

interface StatusResponse {
  status: 'completed' | 'processing' | 'error';
  message: string;
}

const uploadFile = async (
  file: File,
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  setWaitingQueue: React.Dispatch<React.SetStateAction<number>>,
  openSnackbar: (
    message: string,
    severity: 'error' | 'info' | 'success',
  ) => void,
  setDownloadLink: React.Dispatch<React.SetStateAction<string>>,
  setStartDownload: React.Dispatch<React.SetStateAction<boolean>>,
  resolution: number,
  originalFilename: string,
) => {
  // body中包含文件和resolution
  const formData = new FormData();
  formData.append('file', file);
  formData.append('resolution', resolution.toString());
  const originalTitle = originalFilename.split('】')[1].trim();
  const finalFileName = 'AUTOSUB-' + originalTitle;
  formData.append('finalFileName', finalFileName);

  setProcessing(true);
  try {
    const response = await axios.post<UploadResponse>(
      'https://dl.belugasubs.com/api/yz/uploads',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    if (response.data.taskId) {
      if (response.data.taskId === -1) {
        const downloadUrl: string = response.data.url || '';
        setSuccess(true);
        setDownloadLink(downloadUrl);
        setProcessing(false);
        setWaitingQueue(0);
        console.log('downloadUrl:', downloadUrl);
        return;
      }

      const taskId = response.data.taskId;
      let lastStatus = '';
      const checkStatusInterval = setInterval(async () => {
        try {
          const statusResponse = await axios.get<StatusResponse>(
            `https://dl.belugasubs.com/api/yz/status/${taskId}`,
          );
          const { status, message } = statusResponse.data;

          // Directly return if the status is the same as the last one
          if (status === lastStatus) return;
          // Update the lastStatus
          lastStatus = status;

          if (status === 'processing') {
            setWaitingQueue(0);
            setProcessing(true);
            openSnackbar('已排到，正在处理中...', 'success');
            console.log('processing');
          } else if (status === 'completed' || status === 'error') {
            clearInterval(checkStatusInterval);
            setProcessing(false);

            if (status === 'completed') {
              openSnackbar('文件处理完成', 'success');
              setSuccess(true);
              setDownloadLink(message);
              console.log('downloadUrl:', message);
            } else {
              openSnackbar('文件处理失败', 'error');
              setSuccess(false);
              console.log('error:', message);
            }
          } else {
            // message is the position in queue, 10是进制
            const positionInQueue = parseInt(message, 10);
            setWaitingQueue(positionInQueue);
            console.log('waitingQueue:', positionInQueue);
          }
        } catch (error: unknown) {
          let errorMessage = '发生未知错误，请稍后重试。'; // 默认错误消息
          // 停掉轮询
          clearInterval(checkStatusInterval);

          // 检查error是否为AxiosError
          if (axios.isAxiosError(error)) {
            // Axios 错误处理
            if (error.response) {
              // 服务器响应了请求，但状态码不在2xx范围内
              errorMessage = `服务器错误：${error.response.status}`;
              if (
                error.response.data &&
                typeof error.response.data === 'object' &&
                'message' in error.response.data
              ) {
                // 如果错误响应体中包含具体的错误信息，可以使用它
                errorMessage += ` - ${(error.response.data as { message: string }).message}`;
              }
            } else if (error.request) {
              // 请求已发出，但没有收到响应
              errorMessage = '服务器无响应，请检查您的网络连接。';
            } else {
              // 请求设置导致发送请求失败
              errorMessage = '请求失败，请稍后重试。';
            }
          } else if (error instanceof Error) {
            // 处理普通的Error对象
            errorMessage = error.message;
          }

          console.error('Error while checking status:', errorMessage);
          openSnackbar(errorMessage, 'error');
          setProcessing(false);
          setStartDownload(false);
          setSuccess(false);
        }
      }, 5000); // Check every 5 seconds
    }
  } catch (error: unknown) {
    let errorMessage = '发生错误，请稍后重试。'; // 默认错误消息
    if (error instanceof Error) {
      // 处理普通的Error对象
      errorMessage = error.message;
    }
    console.error('Error while uploading file:', errorMessage);
    openSnackbar(errorMessage, 'error');
    setProcessing(false);
    setStartDownload(false);
    setSuccess(false);
  }
};

export default uploadFile;
