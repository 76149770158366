import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import DownloadDialog from '../../components/workspace/tingyi/downloadDialog';
import Guide from '../../components/workspace/tingyi/guide';
import TaskDetailsTable from '../../components/workspace/tingyi/taskDetailsTable';
import { useSnackbar } from '../../context/SnackbarContext';
import { TaskInfo } from '../../types';
import downloadFromBackend from '../../utils/tingyi/downloadFromBackend';

const buttonContent = {
  loading: '正在下载...',
  success: '成功，点击下载',
  error: '下载失败',
  waiting: '点击下载',
};

export default function Tingyi() {
  const { openSnackbar } = useSnackbar();
  const [mediaType, setMediaType] = useState<'cover' | 'subtitle'>('subtitle');
  const [videoUrl, setVideoUrl] = useState('');
  const [taskInfoMessage, setTaskInfoMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [success, setSuccess] = useState(false);
  const [showResultSection, setShowResultSection] = useState(false);
  const [taskInfoHandleStatus, setTaskInfoHandleStatus] = useState(false);
  // 检查是否有多名听译
  const [isMultipleTingYi, setIsMultipleTingYi] = useState(false);
  // 选择的听译（单人情况下直接选择）
  const [selectedTingYi, setSelectedTingYi] = useState('');
  // 检查是否已经自动检查任务信息，不论成功与否
  const [isAutoCheckHandled, setIsAutoCheckHandled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogToggle = () => {
    setDialogOpen(!dialogOpen);
  };
  const [taskInfo, setTaskInfo] = useState<TaskInfo>({
    ytbUrl: '',
    driveUrl: '',
    outputFileName: '',
    contributors: {
      ytber: '',
      originName: '',
      tingYiList: [],
      jiaoDuiList: [],
      shiZhouList: [],
    },
  });

  return (
    <Box
      sx={{
        margin: 2,
        maxWidth: 800,
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Guide />
      <Divider variant='middle' sx={{ my: 2 }} />
      <Box
        component='form'
        onClick={() => {
          if (success) {
            setShowResultSection(false);
            setSuccess(false);
            setTaskInfoHandleStatus(false);
          }
        }}
      >
        <FormControl component='fieldset' sx={{ width: '100%' }}>
          <RadioGroup
            row
            value={mediaType}
            onChange={(event) =>
              setMediaType(event.target.value as 'cover' | 'subtitle')
            }
            sx={{ justifyContent: 'center', mb: 2 }}
          >
            <FormControlLabel
              value='subtitle'
              control={<Radio />}
              label='字幕'
            />
            <FormControlLabel value='cover' control={<Radio />} label='封面' />
          </RadioGroup>
          {mediaType === 'cover' && (
            <TextField
              label='视频链接'
              type='url'
              fullWidth
              value={videoUrl}
              onInput={(e) => {
                const target = e.target as HTMLInputElement;
                setVideoUrl(target.value);
                setShowResultSection(false);
                setTaskInfoHandleStatus(false);
              }}
              sx={{ my: 2 }}
              placeholder='输入视频链接'
            />
          )}
          {mediaType === 'subtitle' && (
            <TextField
              label='任务信息输入'
              multiline
              minRows={15}
              fullWidth
              value={taskInfoMessage}
              onInput={(e) => {
                // Use HTMLTextAreaElement to access the value property
                const target = e.target as HTMLTextAreaElement;
                setTaskInfoMessage(target.value);
                setShowResultSection(false);
                setTaskInfoHandleStatus(false);
                console.log('taskInfoMessage:', taskInfoMessage);
              }}
              placeholder='直接复制粘贴即可，请勿自行更改任务分配信息。'
              variant='outlined'
              sx={{ my: 2 }}
            />
          )}
          {mediaType === 'subtitle' && (
            <TaskDetailsTable
              taskInfo={taskInfo}
              setTaskInfo={setTaskInfo}
              taskInfoMessage={taskInfoMessage}
              taskInfoHandleStatus={taskInfoHandleStatus}
              setTaskInfoHandleStatus={setTaskInfoHandleStatus}
              selectedTingYi={selectedTingYi}
              setSelectedTingYi={setSelectedTingYi}
              isMultipleTingYi={isMultipleTingYi}
              setIsMultipleTingYi={setIsMultipleTingYi}
              isHandled={isAutoCheckHandled}
              setIsHandled={setIsAutoCheckHandled}
            />
          )}
          {(mediaType === 'cover' || taskInfoHandleStatus) && (
            <Button
              variant='contained'
              color={
                showResultSection ? (success ? 'success' : 'error') : 'primary'
              }
              fullWidth
              disabled={isLoading}
              sx={{
                mt: 2,
                width: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              startIcon={
                isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : success ? (
                  <DownloadIcon />
                ) : null
              }
              onClick={() => {
                if (showResultSection && !success) {
                  setShowResultSection(false);
                  setTaskInfoHandleStatus(false);
                  return;
                }
                if (showResultSection && success) {
                  // Use downloadLink for direct download with the 'download' attribute
                  const link = document.createElement('a');
                  link.href = downloadLink;
                  // Add download attribute without specifying filename
                  if (mediaType === 'cover') {
                    link.setAttribute('download', 'thumbnail.jpg');
                    openSnackbar(
                      '上传完成后记得填表，更新人员状态和KPI',
                      'info',
                    );
                  } else {
                    link.setAttribute('download', '');
                    openSnackbar('成功下载，祝你任务愉快！', 'success');
                    handleDialogToggle();
                  }
                  document.body.appendChild(link);
                  link.click();
                  // Clean up
                  document.body.removeChild(link);
                } else {
                  // start download
                  downloadFromBackend({
                    mediaType,
                    videoUrl,
                    taskInfo,
                    setIsLoading,
                    setShowResultSection,
                    setDownloadLink,
                    setSuccess,
                    openSnackbar,
                  });
                  if (mediaType === 'subtitle') {
                    console.log('taskInfo:', taskInfo);
                  } else {
                    console.log('videoUrl:', videoUrl);
                  }
                }
              }}
            >
              {isLoading
                ? buttonContent.loading
                : showResultSection
                  ? success
                    ? buttonContent.success
                    : buttonContent.error
                  : buttonContent.waiting}
            </Button>
          )}
        </FormControl>
        <DownloadDialog
          dialogOpen={dialogOpen}
          handleDialogToggle={handleDialogToggle}
        />
      </Box>
    </Box>
  );
}
