import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import Copyright from '../../components/Copyright';
import TopAppBar from '../../components/workspace/topAppBar';

import Tingyi from './tingyi';
import Yazhi from './yazhi';

const drawerWidth = 240;

export default function Workspace() {
  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentComponent = () => {
    switch (location.pathname) {
      case '/':
        return <Tingyi />;
      case '/tingyi':
        return <Tingyi />;
      case '/yazhi':
        return <Yazhi />;
      default:
        return <Tingyi />;
    }
  };

  // SideDrawer is open by default on desktop and closed on mobile
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(!isMobile);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  return (
    <Box sx={{ display: 'flex' }}>
      <TopAppBar
        handleDrawerToggle={handleDrawerToggle}
        drawerOpen={drawerOpen}
        isMobile={isMobile}
        navigate={navigate}
      />
      {/* 根据Drawer状态动态调整宽度 */}
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          marginTop: '64px',
          // 确保任何情况下右边距都为0
          marginRight: '0px',
          // 移动端不调整margin，桌面端根据drawer状态调整margin/width
          // 根据drawer状态调整margin，在打开时为drawerWidth，关闭时为0
          marginLeft: isMobile
            ? '0px'
            : `-${!drawerOpen ? `${drawerWidth}px` : '0px'}`,
          overflowX: 'hidden', // 添加以避免横向滚动条
          ...(isMobile &&
            drawerOpen && {
              position: 'fixed',
              // 确保在Drawer和AppBar之下
              zIndex: theme.zIndex.drawer - 2,
            }),
        }}
      >
        {/* 移动端增加背景虚化遮罩提高可读性 */}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={drawerOpen && isMobile}
          onClick={handleDrawerToggle}
        />
        {getCurrentComponent()}
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Box>
    </Box>
  );
}
