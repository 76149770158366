import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function SideDrawer({
  open,
  isMobile,
  navigate,
}: {
  open: boolean;
  isMobile: boolean;
  navigate: NavigateFunction;
}) {
  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Drawer
      sx={{
        'width': isMobile && !open ? 0 : drawerWidth,
        'flexShrink': 0,
        '& .MuiDrawer-paper': {
          width: isMobile && !open ? 0 : drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant='persistent'
      anchor='left'
      open={open}
    >
      <DrawerHeader />
      <List>
        <ListItem key='tingyi' disablePadding>
          <ListItemButton onClick={() => handleNavigate('/tingyi')}>
            <ListItemIcon>
              <HeadphonesIcon />
            </ListItemIcon>
            <ListItemText primary='听译' />
          </ListItemButton>
        </ListItem>
        <ListItem key='jiaodui' disablePadding>
          <ListItemButton>
            {/* <ListItemButton onClick={() => handleNavigate('/jiaodui')}> */}
            <ListItemIcon>
              <FactCheckIcon />
            </ListItemIcon>
            <ListItemText primary='校对（开发中）' />
          </ListItemButton>
        </ListItem>
        <ListItem key='yazhi' disablePadding>
          <ListItemButton onClick={() => handleNavigate('/yazhi')}>
            <ListItemIcon>
              <VideoSettingsIcon />
            </ListItemIcon>
            <ListItemText primary='压制' />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key='' disablePadding>
          <ListItemButton>
            {/* <ListItemButton onClick={() => handleNavigate('/')}> */}
            <ListItemIcon>
              <EmojiObjectsIcon />
            </ListItemIcon>
            <ListItemText primary='我还没想好' />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
