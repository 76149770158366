import React from 'react';

import { TaskInfo } from '../../types';

interface DownloadParams {
  mediaType: 'cover' | 'subtitle';
  videoUrl: string;
  taskInfo: TaskInfo;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setShowResultSection: React.Dispatch<React.SetStateAction<boolean>>;
  setDownloadLink: React.Dispatch<React.SetStateAction<string>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  openSnackbar: (message: string, variant: 'success' | 'error') => void;
}

const downloadFromBackend = async ({
  mediaType,
  videoUrl,
  taskInfo,
  setIsLoading,
  setShowResultSection,
  setDownloadLink,
  setSuccess,
  openSnackbar,
}: DownloadParams) => {
  setIsLoading(true);
  setShowResultSection(false);
  setSuccess(false);

  // Define the API endpoint
  const tingyiDownloadAPI = 'https://dl.belugasubs.com/api/subs';

  // Check if the mediaType is cover and link is correct
  if (
    mediaType === 'cover' &&
    (!videoUrl.trim() || !videoUrl.startsWith('https://youtu.be/'))
  ) {
    openSnackbar('请提供正确的视频链接', 'error');
    setIsLoading(false);
    return;
  }

  // Construct the body based on mediaType
  const body =
    mediaType === 'cover'
      ? { content: videoUrl, mediaType }
      : { content: taskInfo, mediaType };

  // Send the request
  const fetchPromise = fetch(tingyiDownloadAPI, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  // 设置30秒超时
  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => {
      reject(new Error('请求超时，请稍后再试'));
    }, 30000),
  ); // 30秒后触发reject

  Promise.race([fetchPromise, timeoutPromise])
    .then(async (value: unknown) => {
      if (!(value instanceof Response)) {
        throw new Error('Unexpected response type');
      }
      const response = value as Response;
      if (!response.ok) {
        // 手动抛出错误，尝试解析JSON错误响应
        const errorResponse = await response.json();
        const errorMessage =
          errorResponse.error || `HTTP错误: ${response.status}`;
        // 使用服务器返回的错误消息
        throw new Error(errorMessage);
      }
      // 假设成功响应返回的是下载链接
      const link = await response.text();
      console.log('下载链接:', link);
      console.log('response:', response);
      setDownloadLink(link);
      setSuccess(true);
      setShowResultSection(true);
      openSnackbar('下载成功！', 'success');
    })
    .catch((error: Error) => {
      console.error('下载失败:', error);
      openSnackbar(error.message, 'error');
    })
    .finally(() => {
      setIsLoading(false);
      setShowResultSection(true);
    });
};

export default downloadFromBackend;
