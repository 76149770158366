import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

export default function Guide() {
  return (
    <Box
      sx={{
        margin: 2,
        maxWidth: 600,
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Typography variant='h5' component='div'>
        仅限字幕组内部使用，流程仅为字幕组设计，仅适用于字幕组内部任务流程，之后会开发公开版本。
        <br />
        <br />
        因自动打轴非常占用后端服务器资源，请务必再三检查校对文件本身无错后再使用。
        <br />
        <br />
        若发现分辨率选择错误，请在完成后联系组长进行更改。
      </Typography>
      <br />
      <br />
      <Typography variant='h6' component='div'>
        操作指南
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary='1. 开始前：'
            secondary={
              <>
                <Typography component='span'>
                  a)
                  校对的docx文件，请检查正文、注释、硬字幕是否符合要求。（是否有$$在每个部分前）
                </Typography>
                <br />
                <Typography component='span'>
                  b) 删除没有使用的注释或硬字幕，不要留空。
                </Typography>
                <br />
                <Typography component='span'>
                  c)
                  分辨率请通过下载的视频进行确认，仅科技数码组的Dave2D和TheVerge是2160p。
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary='2. 注意：'
            secondary={
              <>
                <Typography component='span'>
                  a) 因服务器限制，文件名请勿包含特殊字符。
                </Typography>
                <br />
                <Typography component='span'>
                  b) 等待后端服务器进行实时处理，处理时间
                  <u style={{ fontWeight: 'bold' }}>大约需要3-10分钟</u>
                  （取决于你要处理的视频长度）。
                </Typography>
                <br />
                <Typography component='span'>
                  c) 在等待过程中，请
                  <u style={{ fontWeight: 'bold' }}>不要关闭页面</u>
                  ，否则将导致处理失败。
                </Typography>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary='3. 完成后：'
            secondary={
              <>
                <Typography component='span'>
                  a) 下载完成的ass文件，检查是否有偏移（可能会有1s偏移）。
                </Typography>
                <br />
                <Typography component='span'>
                  b)
                  如果有偏移，在ArcTime中打开ass文件，并选中所有偏移的字幕块，然后统一调整偏移量。
                </Typography>
                <br />
                <Typography component='span'>
                  c)
                  若有注释或硬字幕，请手动添加，注释添加功能正在开发中，即将上线，硬字幕无法自动化。
                </Typography>
              </>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
}
