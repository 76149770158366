import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface DownloadDialogProps {
  dialogOpen: boolean;
  handleDialogToggle: () => void; // Used to close the dialog
}

const DownloadDialog: React.FC<DownloadDialogProps> = ({
  dialogOpen,
  handleDialogToggle,
}) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogToggle}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle id='responsive-dialog-title'>听译流程注意事项</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>1. 字幕切换迅速，翻译应简洁、高效、精准、本土化。</p>
          <p>2. 中文在英文上，注意标点的使用，巨中断句使用双空格。</p>
          <p>3. 在翻译前应先重新断句（也可以边断句边翻译），注意断句的位置。</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleDialogToggle();
          }}
          autoFocus
        >
          确定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDialog;
