import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material';

import FileDetailsTable from '../../components/workspace/yazhi/fileDetailsTable';
import Guide from '../../components/workspace/yazhi/guide';
import { useSnackbar } from '../../context/SnackbarContext';
import { jiaoduiFileNameInfo } from '../../types';
import uploadFileHandler from '../../utils/yazhi/fileInfoHandle';
import uploadFile from '../../utils/yazhi/fileUploadUtils';

// 定义StyledBox来设置自定义样式
const StyledBox = styled(Box)(({ theme }) => ({
  'maxWidth': 550,
  'display': 'flex',
  'flexDirection': 'column',
  'justifyContent': 'center',
  'textAlign': 'center',
  'marginRight': 'auto',
  'marginLeft': 'auto',
  'alignItems': 'center',
  'padding': theme.spacing(2),
  'border': `2px dashed ${theme.palette.divider}`,
  'borderRadius': theme.shape.borderRadius,
  'cursor': 'pointer',
  '&:hover, &:dragover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

// 定义StyledInput使其不可见
const StyledInput = styled('input')({
  display: 'none',
});

export default function Yazhi() {
  const { openSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [FilenameInfo, setFilenameInfo] = useState<jiaoduiFileNameInfo>({
    jiaodui: '',
    youtuber: '',
    title: '',
  });
  const [originalFilename, setOriginalFilename] = useState('');
  const [resolution, setResolution] = useState(0);
  // 上传文件处理
  const [startDownload, setStartDownload] = useState(false);
  const [waitingQueue, setWaitingQueue] = useState(999);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  // 文件选择处理
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (
        file.type !==
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.name.endsWith('.docx') === false
      ) {
        openSnackbar('请上传docx文件', 'error');
        return;
      }
      const tempFileNameInfo = uploadFileHandler({
        filename: file.name,
        openSnackbar: openSnackbar,
      });
      if (tempFileNameInfo) {
        setOriginalFilename(file.name);
        setFilenameInfo(tempFileNameInfo);
        openSnackbar('请确认这是你要处理的视频，并检查分辨率', 'info');
        setSelectedFile(file);
      }
    } else {
      openSnackbar('上传失败，请检查文件内容/类型', 'error');
    }
  };

  // 文件拖放处理
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      if (
        file.type !==
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        file.name.endsWith('.docx') === false
      ) {
        openSnackbar('请上传docx文件', 'error');
        return;
      }
      const tempFileNameInfo = uploadFileHandler({
        filename: file.name,
        openSnackbar: openSnackbar,
      });
      if (tempFileNameInfo) {
        setOriginalFilename(file.name);
        setFilenameInfo(tempFileNameInfo);
        openSnackbar('请确认这是你要处理的视频，并检查分辨率', 'info');
        setSelectedFile(file);
      }
    } else {
      openSnackbar('上传失败，请检查文件内容/类型', 'error');
    }
  };

  return (
    <Box
      sx={{
        margin: 2,
        maxWidth: 1000,
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Guide />
      <Divider variant='middle' sx={{ my: 2 }} />
      <StyledBox
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()} // 防止默认处理 (禁用浏览器默认行为)
      >
        <label htmlFor='upload-button-file' style={{ cursor: 'pointer' }}>
          <StyledInput
            accept='.docx'
            id='upload-button-file'
            type='file'
            onChange={handleFileChange}
          />
          {!selectedFile && (
            <>
              <UploadFileIcon
                sx={{ fontSize: 40, margin: 2 }}
                color='primary'
              />
              <Typography variant='body1' gutterBottom>
                拖放文件到此处上传
              </Typography>
              <Typography variant='caption' display='block' gutterBottom>
                或者，你可以点击这里手动上传
              </Typography>
            </>
          )}
          {selectedFile && (
            <>
              <FileUploadIcon
                sx={{ fontSize: 40, margin: 2 }}
                color='success'
              />
              <Typography variant='body2' sx={{ mt: 2, textAlign: 'center' }}>
                {selectedFile.name}
              </Typography>
            </>
          )}
        </label>
      </StyledBox>

      <RadioGroup
        row
        // 将整数转换回字符串，以匹配value的期望类型
        value={resolution.toString()}
        // 将选中的值从字符串转换为整数
        // 10表示使用十进制
        onChange={(event) => {
          setResolution(parseInt(event.target.value, 10));
          // 选择分辨率后，显示被选中的分辨率
          openSnackbar(
            `分辨率已选择为${event.target.value === '0' ? '1080p' : '2160p'}`,
            'info',
          );
        }}
        sx={{ justifyContent: 'center', mb: 2, mt: 5 }}
      >
        <FormControlLabel value='0' control={<Radio />} label='1080p' />
        <FormControlLabel value='1' control={<Radio />} label='2160p' />
      </RadioGroup>

      {selectedFile && (
        <>
          <FileDetailsTable
            filenameInfo={FilenameInfo}
            resolution={resolution}
          />
          <Button
            variant='contained'
            fullWidth
            disabled={startDownload && (waitingQueue > 0 || processing)}
            color={startDownload ? (success ? 'success' : 'error') : 'primary'}
            startIcon={
              startDownload && (waitingQueue > 0 || processing) ? (
                <CircularProgress size={24} color='inherit' />
              ) : success ? (
                <DownloadIcon />
              ) : null
            }
            sx={{
              display: 'flex',
              mt: 2,
              maxWidth: 250,
              marginTop: 5,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            onClick={async () => {
              if (!startDownload && selectedFile) {
                setStartDownload(true);
                setWaitingQueue(999);
                await uploadFile(
                  selectedFile, // 用户选择的文件
                  setProcessing, // 更新处理状态的函数
                  setSuccess, // 更新成功状态的函数
                  setWaitingQueue, // 更新等待队列状态的函数
                  openSnackbar, // 用于显示通知的函数
                  setDownloadLink, // 更新下载链接状态的函数
                  setStartDownload, // 更新开始下载状态的函数
                  resolution, // 用户选择的分辨率
                  originalFilename, // 用户选择的文件名
                );
              } else if (success && downloadLink) {
                // Use downloadLink for direct download with the 'download' attribute
                const link = document.createElement('a');
                link.href = downloadLink;
                // Add download attribute without specifying filename
                link.setAttribute('download', '');
                openSnackbar('成功下载，祝你任务愉快！', 'success');
                document.body.appendChild(link);
                link.click();
                // Clean up
                document.body.removeChild(link);
              }
            }}
          >
            {startDownload
              ? waitingQueue > 0
                ? `队列中，剩余${waitingQueue}个任务`
                : processing
                  ? '已排到，正在处理你的文件...'
                  : success
                    ? '已完成，点击下载'
                    : '失败，请查看弹窗'
              : '确认无误，点击开始处理'}
          </Button>
        </>
      )}
    </Box>
  );
}
