import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Alert, AlertColor, AlertTitle, Slide, Snackbar } from '@mui/material';

// 定义Snackbar消息的类型
interface SnackbarMessage {
  message: string;
  severity: AlertColor;
  id: number; // 增加一个id字段来唯一标识每个Snackbar
}

interface SnackbarContextType {
  openSnackbar: (message: string, severity: AlertColor) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined,
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

const alertTitle: { [key in AlertColor]: string } = {
  error: 'Error',
  info: 'Info',
  success: 'Success',
  warning: 'Warning',
};

export const SnackbarProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [snackPack, setSnackPack] = useState<SnackbarMessage[]>([]);

  const openSnackbar = (message: string, severity: AlertColor) => {
    // 根据时间，生成一个新的id
    const id = new Date().getTime();
    setSnackPack((prev) => [...prev, { message, severity, id }]);
  };

  const handleClose = (id: number) => () => {
    setSnackPack((prev) => prev.filter((snack) => snack.id !== id));
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      {snackPack.map(({ id, message, severity }) => (
        <Snackbar
          key={id}
          open
          // 5秒后自动关闭
          autoHideDuration={5000}
          onClose={handleClose(id)}
          TransitionComponent={(props) => <Slide {...props} direction='left' />}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={handleClose(id)}
            severity={severity}
            sx={{ width: '100%' }}
          >
            <AlertTitle>{alertTitle[severity]}</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      ))}
    </SnackbarContext.Provider>
  );
};
